import {
  Col,
  Row,
  Button,
  Typography,
  Card,
  Modal,
  Checkbox,
  Tooltip,
} from "antd";
import { Image as ImageAntd } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import { DetailPopup } from "./DetailPopup";
import { useTranslation } from "react-i18next";
import { PostTransactionType, ProductDataType } from "../models/dataType";
import { useNavigate } from "react-router-dom";
import { useShortLists } from "../contexts/ShortListContext";
import { UserContext } from "../contexts/UserInfoContext";
import { postTransaction } from "../services/TransactionService";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useMsalWrapper } from "../contexts/MSALContext";
import { toast } from "react-toastify";
import { titleCase } from "./PerformanceMetrics";

const { Text } = Typography;

interface ResultProductCardProps {
  data: ProductDataType;
  showButtons?: boolean;
  shortListed?: boolean;
  onShortListed?: (code: string) => void;
  selectItem?: (token: string, code: string, selected: boolean) => void;
  showImage?: boolean;
}

export const ResultProductCard = ({
  data,
  showButtons = true,
  shortListed = false,
  onShortListed,
  selectItem,
  showImage = true,
}: ResultProductCardProps) => {
  const [quicklook, setQuicklook] = useState(false);
  const navigate = useNavigate();

  const user: any = useContext(UserContext);

  const { acquireToken } = useMsalWrapper();

  const { t } = useTranslation();

  const { addShortListId, deleteShortListId } = useShortLists();

  const onShortList = async () => {
    if (!data?.item_number) return;

    if (onShortListed) {
      onShortListed(data.item_number);
    }

    if (shortListed) {
      await deleteShortListId("product", data.item_number);
      return;
    }

    await addShortListId("product", data.item_number);
  };

  const goToProduct = async () => {
    const token = await acquireToken();

    if (!token || !user) return;

    const transaction: PostTransactionType = {
      user: user.token,
      method: "GET",
      path: "Detail - product",
      response: "200",
      accessedDate: parseInt(Date.now().toString()),
      payload: JSON.stringify({ id: data.product_id }),
    };

    await postTransaction(token, transaction);

    navigate(`/product/${data.item_run_number}`);
  };

  const onSelect = (e: CheckboxChangeEvent) => {
    if (!data) return;

    if (!selectItem) return;

    selectItem("product", data.item_number, e.target.checked);
  };

  const handleQuicklook = () => {
    setQuicklook(true);
  };

  const onImageSearch = async () => {
    try {
      const imageResponse = await fetch(
        `${data.image_url}${process.env.REACT_APP_SAS_TOKEN}&xyz=${Date.now()}`,
        {
          method: "GET",
          mode: "cors",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      // @ts-ignore
      // const img = new Image();
      // img.crossOrigin = 'Anonymous';
      //
      // img.onload = () => {
      //   console.log('onload')
      //   const canvas = document.createElement('canvas');
      //   canvas.width = img.width;
      //   canvas.height = img.height;
      //   const context = canvas.getContext('2d');
      //   console.log('context')
      //   console.log(context)
      //   if (context) {
      //     context.drawImage(img, 0, 0, img.width, img.height);
      //
      //     const base64 = canvas.toDataURL('image/jpeg'); // You can specify the desired image format
      //     console.log('yooo')
      //     console.log(base64)
      //   }
      // };

      // const reader= new FileReader()
      // //
      // const imgElement = document.getElementById('productThumbnail') as HTMLImageElement;
      // if (imgElement) {
      //   // Create a canvas element
      //   const canvas = document.createElement('canvas');
      //   const context = canvas.getContext('2d');
      //
      //   // Set the canvas dimensions to match the image
      //   canvas.width = 150;
      //   canvas.height = 150;
      //   if (context) {
      //     // Draw the image on the canvas
      //     context.drawImage(imgElement, 0, 0, 150, 150);
      //
      //     // Convert the canvas content to a base64 string (PNG format in this example)
      //     const base64String = canvas.toDataURL('image/png');
      //     console.log(base64String);
      //   }
      //
      // }

      //
      // const  xhr=new XMLHttpRequest();
      // console.log('wa')
      // xhr.open("GET", data.image_url + process.env.REACT_APP_SAS_TOKEN, true);
      // xhr.responseType = 'blob';
      // console.log('yo')
      // xhr.onload = function(e) {
      //     console.log('onload')
      //     console.log(this.response)
      //     const blob = this.response;
      //     reader.readAsDataURL(blob);
      //     reader.onloadend = function() {
      //         const base64data = reader.result;
      //         console.log(base64data);
      //     }
      // }

      // reader.readAsDataURL()
      //
      // img.src = data.image_url + process.env.REACT_APP_SAS_TOKEN;
      // console.log(img)
      const imageType = "image/jpeg";
      const blob = await imageResponse.blob();
      const fileObj = new File([blob], data.item_number, { type: imageType });
      const file = {
        file: {
          lastModified: fileObj.lastModified,
          name: fileObj.name,
          originFileObj: fileObj,
          type: fileObj.type,
        },
      };
      navigate(`/image/search`, { state: { file: file } });
    } catch (e) {
      console.error(e);
      toast.error("Image unavailable at this time");
    }
  };

  const onFindSupplier = () => {
    if (!data.supplier_code) return;
    navigate(
      `/supplier/${
        data.supplier_code[1] ? data.supplier_code : data.supplier_code[0]
      }`,
    );
  };

  return (
    <>
      <Card className="card">
        <Row gutter={8} style={{ position: "relative" }}>
          <Col flex={"0 1 auto"}>
            {/*<img id="productThumbnail" src={data.image_url + process.env.REACT_APP_SAS_TOKEN} height={150}/>*/}
            <ImageAntd
              className="rounded"
              src={
                showImage
                  ? data.image_url + process.env.REACT_APP_SAS_TOKEN
                  : process.env.PUBLIC_URL + "/fallbackImage.png"
              }
              fallback={process.env.PUBLIC_URL + "/fallbackImage.png"}
              height={150}
              width={150}
            />
          </Col>
          <Col flex="auto">
            <Row>
              <Col>
                {/*<Text type="secondary"> {`Description: `} </Text>*/}
                <Text strong> {data.item_description} </Text>
              </Col>
            </Row>
            {/*<Row><br/></Row>*/}
            <Row>
              <br />
            </Row>
            <Row>
              <Col>
                <Tooltip title={"Product Family"}>
                  <Text type="secondary">{t("Product Family") + " "}</Text>
                  <Text>{titleCase(data.product_family)}</Text>
                </Tooltip>
              </Col>
            </Row>
            <Row className="result-text-row" gutter={24}>
              <Col>
                <Tooltip title={"Product Category"}>
                  <Text type="secondary">{t("Product Category:") + " "}</Text>
                  <Text>{titleCase(data.product_category)}</Text>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={"Product Category Group"}>
                  <Text type="secondary">
                    {t("Product Category Group:") + " "}
                  </Text>
                  <Text>{titleCase(data.product_category_group)}</Text>
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text type="secondary"> {`ID: `} </Text>
                <Text> {data.item_number} </Text>
              </Col>
            </Row>
          </Col>
          <Col
            span={4}
            flex={"auto"}
            style={{ position: "absolute", top: 0, right: 0 }}>
            <Row className="card-buttons" wrap={false}>
              <Button
                style={{
                  backgroundColor: shortListed ? "var(--red)" : "",
                  color: shortListed ? "white" : "black",
                }}
                onClick={onShortList}>
                {t("Shortlist")}
              </Button>
              {showButtons && (
                <>
                  <Button onClick={onFindSupplier}>{t("Find Supplier")}</Button>
                  <Button onClick={goToProduct}>{t("View Product")}</Button>
                  <Button onClick={onImageSearch}>{t("Find Similar")}</Button>
                  <Button onClick={handleQuicklook}>
                    <InfoCircleOutlined />
                  </Button>
                </>
              )}
              {selectItem && <Checkbox onChange={onSelect} />}
            </Row>
          </Col>
        </Row>
        {!showButtons && <DetailPopup details={data} image={false} />}
      </Card>
      <Modal
        mask={false}
        open={quicklook}
        onCancel={() => setQuicklook(false)}
        footer={null}
        width={750}>
        <DetailPopup details={data} />
      </Modal>
    </>
  );
};
