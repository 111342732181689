import type { SearchContextState } from "@elastic/search-ui";
import React from "react";
import { Rename, BaseContainerProps } from "@elastic/react-search-ui-views";
import appendClassName from "../utils/elasticUtils/appendClassName";

export type PagingInfoContainerContext = Pick<
  SearchContextState,
  "pagingStart" | "pagingEnd" | "resultSearchTerm" | "totalResults"
>;

export type PagingInfoViewProps = Rename<
  BaseContainerProps & PagingInfoContainerContext,
  {
    pagingStart: "start";
    resultSearchTerm: "searchTerm";
    pagingEnd: "end";
  }
>;

export type PagingInfoContainerProps = BaseContainerProps &
  PagingInfoContainerContext & {
    view?: React.ComponentType<PagingInfoViewProps>;
  };

function CustomPagingInfo({
  className,
  end,
  searchTerm,
  start,
  totalResults,
  ...rest
}: PagingInfoViewProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={appendClassName(
        "sui-paging-info",
        `${className} flex flex-col`,
      )}
      {...rest}>
      <div style={{ height: 45 }}>
        Showing{" "}
        <strong>
          {start} - {end}
        </strong>{" "}
        out of{" "}
        {totalResults < 2000 ? (
          <strong>{totalResults}</strong>
        ) : (
          <>
            <strong>2000</strong>
          </>
        )}
        {searchTerm && (
          <>
            {" "}
            for: <em>{searchTerm}</em>
          </>
        )}
      </div>
      {totalResults > 2000 && (
        <div style={{ marginBottom: 20 }}>
          Please narrow your search to access a total of{" "}
          <strong>{totalResults}</strong> results
        </div>
      )}
    </div>
  );
}

export default CustomPagingInfo;
