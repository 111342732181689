import { Layout, Row, Col, Spin } from "antd";
import { NavBar } from "./NavBar";
import {
  ErrorBoundary,
  Paging,
  PagingInfo,
  WithSearch,
} from "@elastic/react-search-ui";
import { SearchFilters } from "./SearchFilters";
import { ResultCard } from "./ResultCard";
import { ResultProductCard } from "./ResultProductCard";
import {
  SourceType,
  formatProductResult,
  formatFacilityResult,
} from "../models/dataType";
import { SearchMaintenancePage } from "../routes/SearchMaintenancePage";
import { useShortLists } from "../contexts/ShortListContext";
import { isShortListed } from "../services/ShortListService";
import { useCompareLists } from "../contexts/CompareListContext";
import CustomPagingInfo from "./CustomPagingInfo";
const { Header, Content } = Layout;

export const CategoryLayout = ({
  activeCategory,
}: {
  activeCategory: SourceType | "product";
}) => {
  const { shortListIds } = useShortLists();
  const { isItemCompare } = useCompareLists();
  if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
    return <SearchMaintenancePage />;
  } else {
    return (
      <Layout className="frame">
        <Header id="search-header">
          <NavBar />
        </Header>
        <Content className="content">
          <WithSearch
            mapContextToProps={({ results, isLoading }) => ({
              results,
              isLoading,
            })}>
            {({ results, isLoading }) => {
              return (
                <ErrorBoundary>
                  <Row id="search-paging">
                    <PagingInfo view={CustomPagingInfo} />
                    <Paging />
                  </Row>
                  <Row className="search-body" wrap={false}>
                    <Col>
                      <SearchFilters />
                    </Col>
                    <Col
                      className="results"
                      flex={"auto"}
                      style={{ marginBottom: "50px" }}>
                      {isLoading ? (
                        <Spin tip="Loading..."></Spin>
                      ) : activeCategory !== "product" ? (
                        <>
                          {results?.map((result: any) => {
                            const data = formatFacilityResult(
                              activeCategory,
                              result,
                            );
                            const isShortList = isShortListed(
                              data.code,
                              shortListIds,
                            );
                            const compare = isItemCompare(
                              activeCategory,
                              data.code,
                            );
                            return (
                              <ResultCard
                                source={activeCategory}
                                result={result}
                                mappedResult={formatFacilityResult(
                                  activeCategory,
                                  result,
                                )}
                                shortListed={isShortList}
                                isCompared={compare}
                                key={result.id.raw}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {results?.map((result: any) => {
                            const itemNumber = result.item_number?.raw
                              ? result.item_number.raw
                              : result.item_number;
                            const isShortList = isShortListed(
                              itemNumber,
                              shortListIds,
                            );
                            return (
                              <ResultProductCard
                                data={formatProductResult(result)}
                                shortListed={isShortList}
                                key={result.id.raw}
                              />
                            );
                          })}
                        </>
                      )}
                    </Col>
                  </Row>
                </ErrorBoundary>
              );
            }}
          </WithSearch>
        </Content>
      </Layout>
    );
  }
};
