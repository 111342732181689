interface ImageSearchPagingInfoProps {
  page: number;
  totalResults: number;
  currentPage: number;
}

export const ImageSearchPagingInfo: React.FC<ImageSearchPagingInfoProps> = ({
  page,
  totalResults,
  currentPage,
}) => {
  const startIndex = 1 + 20 * (page - 1);
  const endIndex = currentPage + 20 * (page - 1);
  return (
    <div className="sui-paging-info">
      <div>
        Showing{" "}
        <strong>
          {startIndex} - {endIndex}
        </strong>{" "}
        out of <strong>{totalResults}</strong>
      </div>
      {totalResults > 2000 && (
        <div style={{ marginTop: 20 }}>
          Please narrow your search to access a total of{" "}
          <strong>{totalResults}</strong> results
        </div>
      )}
    </div>
  );
};
