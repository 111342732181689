import { Card } from "antd";
import { useCallback, useEffect, useState } from "react";
import FacetComponent from "./ValueFacetCustom";
import RangeFacetComponent from "./RangeFacetCustom";
import { useSearchParams } from "react-router-dom";

interface FacetContainerProps {
  fieldId: string;
  label: string;
  options: any[];
  onChangeFilters?: (value: any) => void;
  isDate?: boolean;
  category: string;
}

const FacetContainer = ({
  fieldId,
  label,
  options,
  onChangeFilters,
  isDate = false,
  category,
}: FacetContainerProps) => {
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    setFilters([]);
  }, [category]);

  const handleSelect = (newValue: string) => {
    setFilters((prevItems) => {
      const updatedFilters = [...prevItems, newValue];
      onChangeFilters && onChangeFilters({ [fieldId]: updatedFilters });
      return updatedFilters;
    });
  };

  const handleRemove = (valueToRemove: string) => {
    setFilters((prevItems) => {
      const updatedFilters = prevItems.filter((item) => item !== valueToRemove);
      onChangeFilters && onChangeFilters({ [fieldId]: updatedFilters });
      return updatedFilters;
    });
  };
  return (
    <FacetComponent
      label={label}
      options={options}
      onSelect={handleSelect}
      onRemove={handleRemove}
      isDate={isDate}
      showMore={options.length > 5}
      category={category}
    />
  );
};

const RangeFacetContainer = ({
  fieldId,
  label,
  options,
  onChangeFilters,
  isDate = false,
  category,
}: FacetContainerProps) => {
  const [filters, setFilters] = useState<{
    [key: string]: { from?: string; to?: string };
  }>({});

  useEffect(() => {
    // Reset filters when category changes
    setFilters({});
  }, [category]);

  const handleSelect = (range: { from?: string; to?: string }) => {
    setFilters((prevFilters) => {
      const updatedFilters = { [fieldId]: range };

      onChangeFilters && onChangeFilters(updatedFilters);
      return updatedFilters;
    });
  };

  const handleRemove = () => {
    setFilters((prevFilters) => {
      const updatedFilters = { [fieldId]: {} };

      onChangeFilters && onChangeFilters(updatedFilters);
      return updatedFilters;
    });
  };

  return (
    <RangeFacetComponent
      label={label}
      options={options}
      onSelect={handleSelect}
      onRemove={handleRemove}
      isDate={isDate}
      showMore={options.length > 5}
      category={category}
    />
  );
};

interface SearchFiltersProps {
  facets: any;
  onSearch?: (value: any) => void;
  category?: any;
}

export const SearchFiltersCustom = ({
  facets,
  onSearch,
  category,
}: SearchFiltersProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [availableFacetsList, setAvailableFacets] = useState(facets);
  const availableFacets = Object.keys(facets) as any;
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);

  useEffect(() => {
    setAvailableFacets(facets);
  }, [facets]);

  useEffect(() => {
    setSelectedFilters([]);
  }, [category, searchParams]);

  const handleonChangeFilters = useCallback(
    (newFilters: any) => {
      setSelectedFilters((prevFilters) => {
        const filterMap: { [key: string]: any } = {};

        prevFilters.forEach((filter) => {
          const key = Object.keys(filter.any[0])[0];
          filterMap[key] = filter.any[0][key];
        });

        for (const key in newFilters) {
          const newFilterValue = newFilters[key];

          if (
            typeof newFilterValue === "object" &&
            (newFilterValue.from || newFilterValue.to)
          ) {
            Object.keys(filterMap).forEach((filterKey) => {
              if (
                typeof filterMap[filterKey] === "object" &&
                (filterMap[filterKey].from || filterMap[filterKey].to)
              ) {
                delete filterMap[filterKey];
              }
            });

            filterMap[key] = newFilterValue;
          } else if (
            Array.isArray(newFilterValue) &&
            newFilterValue.length > 0
          ) {
            filterMap[key] = newFilterValue;
          } else {
            delete filterMap[key];
          }
        }

        const updatedFilters = Object.keys(filterMap).map((key) => ({
          any: [{ [key]: filterMap[key] }],
        }));

        if (onSearch) {
          onSearch(updatedFilters.length > 0 ? updatedFilters : []);
        }

        console.log("Updated filters after change:", updatedFilters);
        return updatedFilters;
      });
    },
    [onSearch],
  );

  return (
    <Card className="filters">
      {availableFacets.includes("demographic__country_name") && (
        <FacetContainer
          fieldId="demographic__country_name"
          label="Country/Region"
          options={facets.demographic__country_name[0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("demographic__city_name") && (
        <FacetContainer
          fieldId="demographic__city_name"
          label="City/Port"
          options={facets.demographic__city_name[0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("sales__export_country_agg.export_country") && (
        <FacetContainer
          fieldId="sales__export_country_agg.export_country"
          label="Sales Export Country"
          options={facets["sales__export_country_agg.export_country"][0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("compliance__social_compliance.A") && (
        <RangeFacetContainer
          fieldId="compliance__social_compliance.A"
          label="Social Compliance A"
          options={facets["compliance__social_compliance.A"][0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("compliance__social_compliance.B") && (
        <RangeFacetContainer
          fieldId="compliance__social_compliance.B"
          label="Social Compliance B"
          options={facets["compliance__social_compliance.B"][0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("compliance__social_compliance.C") && (
        <RangeFacetContainer
          fieldId="compliance__social_compliance.C"
          label="Social Compliance C"
          options={facets["compliance__social_compliance.C"][0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("compliance__social_compliance.D") && (
        <RangeFacetContainer
          fieldId="compliance__social_compliance.D"
          label="Social Compliance D"
          options={facets["compliance__social_compliance.D"][0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("compliance__social_compliance.UNRATED") && (
        <RangeFacetContainer
          fieldId="compliance__social_compliance.UNRATED"
          label="Social Compliance UNRATED"
          options={facets["compliance__social_compliance.UNRATED"][0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("demographic__last_shipment_date") && (
        <FacetContainer
          fieldId="demographic__last_shipment_date"
          label="Last Shipment Date"
          options={facets.demographic__last_shipment_date[0].data}
          onChangeFilters={handleonChangeFilters}
          isDate={true}
          category={category}
        />
      )}
      {availableFacets.includes(
        "sales__product_category_cogs.product_category",
      ) && (
        <FacetContainer
          fieldId="sales__product_category_cogs.product_category"
          label="Sales Product Category"
          options={
            facets["sales__product_category_cogs.product_category"][0].data
          }
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("demographic__FACTORY_TYPE") && (
        <FacetContainer
          fieldId="demographic__FACTORY_TYPE"
          label="Factory Type"
          options={facets.demographic__FACTORY_TYPE[0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("compliance__social_compliance_rating") && (
        <FacetContainer
          fieldId="compliance__social_compliance_rating"
          label="Compliance Rating"
          options={facets.compliance__social_compliance_rating[0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("product_family") && (
        <FacetContainer
          fieldId="product_family"
          label="Product Family"
          options={facets.product_family[0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("product_category") && (
        <FacetContainer
          fieldId="product_category"
          label="Product Category"
          options={facets.product_category[0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
      {availableFacets.includes("product_category_group") && (
        <FacetContainer
          fieldId="product_category_group"
          label="Product Group"
          options={facets.product_category_group[0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}

      {availableFacets.includes("demographic__location_code") && (
        <FacetContainer
          fieldId="demographic__location_code"
          label="Location Code"
          options={facets.demographic__location_code[0].data}
          onChangeFilters={handleonChangeFilters}
          category={category}
        />
      )}
    </Card>
  );
};
