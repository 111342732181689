import React, { useEffect, useState } from "react";
import type { FieldValue } from "@elastic/search-ui";
import appendClassName from "../utils/elasticUtils/appendClassName";
import getFilterValueDisplay from "../utils/elasticUtils/getFilterValueDisplay";
import { format } from "date-fns";

interface FacetProps {
  className?: string;
  label?: string;
  onRemove?: (value: any) => void;
  onSelect?: (value: any) => void;
  options?: any[];
  showMore?: boolean;
  isDate?: boolean;
  category?: string;
}

export default function FacetComponent({
  className,
  label,
  onRemove,
  onSelect,
  options = [],
  showMore,
  isDate = false,
  category,
}: FacetProps) {
  const [selectedOptions, setSelectedOptions] = useState<FieldValue[]>([]);
  const [optionsState, setOptionsState] = useState(options);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [category]);

  const handleSelect = (value: FieldValue) => {
    const updatedSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];
    setSelectedOptions(updatedSelectedOptions);

    if (updatedSelectedOptions.includes(value)) {
      onSelect && onSelect(value);
    } else {
      onRemove && onRemove(value);
    }
  };

  const handleShowMore = () => {
    setIsShowMore(true);
  };

  const displayedOptions = isShowMore ? optionsState : optionsState.slice(0, 5);

  return (
    <fieldset className={appendClassName("sui-facet", className)}>
      <legend className="sui-facet__title">{label}</legend>

      <div className="sui-multi-checkbox-facet">
        {displayedOptions.length < 1 && <div>No matching options</div>}
        {displayedOptions.map((option) => {
          const checked = selectedOptions.includes(option.value);
          return (
            <label
              key={`${getFilterValueDisplay(option.value)}`}
              htmlFor={`example_facet_${label}${getFilterValueDisplay(
                option.value,
              )}`}
              className="sui-multi-checkbox-facet__option-label">
              <div className="sui-multi-checkbox-facet__option-input-wrapper">
                <input
                  data-transaction-name={`facet - ${label}`}
                  id={`example_facet_${label}${getFilterValueDisplay(
                    option.value,
                  )}`}
                  type="checkbox"
                  className="sui-multi-checkbox-facet__checkbox"
                  checked={checked}
                  onChange={() => handleSelect(option.value)}
                />
                <span className="sui-multi-checkbox-facet__input-text">
                  {isDate
                    ? getFilterValueDisplay(
                        format(new Date(option.value as string), "yyyy-MM-dd"),
                      )
                    : getFilterValueDisplay(option.value)}
                </span>
              </div>
              {category !== "Products" && (
                <span className="sui-multi-checkbox-facet__option-count">
                  {option.count}
                </span>
              )}
            </label>
          );
        })}
      </div>

      {showMore && !isShowMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={handleShowMore}
          aria-label="Show more options">
          + More
        </button>
      )}
    </fieldset>
  );
}
