import { Facet } from "@elastic/react-search-ui";
import {
  BooleanFacet,
  SingleSelectFacet,
  MultiCheckboxFacet,
} from "@elastic/react-search-ui-views";
import { Card } from "antd";
import React from "react";
import DateMultiCheckboxFacet from "./DateMultiCheckboxFacet";
import RegionMultiCheckboxFacet from "./RegionMutliCheckboxFacet";

// const SORT_OPTIONS = [
//   {
//     name: "Relevance",
//     value: "",
//     direction: ""
//   },
//   {
//     name: "Factory Name",
//     value: "demographic__factory_name",
//     direction: "asc"
//   },
// ];

export const SearchFilters = () => {
  return (
    <Card className="filters">
      {/* <Sorting label={"Sort by"} sortOptions={SORT_OPTIONS} /> */}
      <Facet
        field="demographic__country_name"
        label="Country/Region"
        view={RegionMultiCheckboxFacet}
        filterType="any"
      />
      <Facet
        field="demographic__city_name"
        label="City / Port"
        view={MultiCheckboxFacet}
        filterType="any"
      />
      <Facet field="demographic__FACTORY_TYPE" label="Factory Type" />
      <Facet field="" label="Season" />
      <Facet
        field="sales__export_country_agg.export_country"
        label="Export Country"
        // view={SingleSelectFacet}
      />
      <Facet
        field="product_family"
        label="Product Family"
        // view={SingleSelectFacet}
      />
      <Facet
        field="product_category_group"
        label="Product Group"
        // view={SingleSelectFacet}
      />
      <Facet
        field="product_category"
        label="Product Category"
        // view={SingleSelectFacet}
      />
      <Facet
        field=""
        label="Material"
        // view={SingleSelectFacet}
      />
      <Facet field="" label="Average Order Quantity" view={SingleSelectFacet} />
      <Facet
        field="compliance__social_compliance_rating"
        label="Compliance Rating"
        view={MultiCheckboxFacet}
        filterType="any"
      />
      <Facet
        field="compliance__social_compliance.A"
        label="Compliance Rating A"
        filterType="any"
      />
      <Facet
        field="compliance__social_compliance.B"
        label="Compliance Rating B"
        filterType="any"
      />
      <Facet
        field="compliance__social_compliance.C"
        label="Compliance Rating C"
        filterType="any"
      />
      <Facet
        field="compliance__social_compliance.D"
        label="Compliance Rating D"
        filterType="any"
      />
      <Facet
        field="compliance__social_compliance.UNRATED"
        label="Compliance Rating Unrated"
        filterType="any"
      />
      <Facet
        field=""
        label="Certifications"
        // view={SingleSelectFacet}
      />
      <Facet
        field="demographic__last_shipment_date"
        label="Last Shipment Date"
        view={DateMultiCheckboxFacet}
      />
      <Facet field="price_range" label="Price Range" view={SingleSelectFacet} />
      <Facet field="Status" label="status" view={BooleanFacet} />
      <Facet
        field="sales__product_category_cogs.product_category"
        label="Supplier Product Category"
        view={MultiCheckboxFacet}
      />
    </Card>
  );
};
