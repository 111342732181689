import React, { useEffect, useState } from "react";

import appendClassName from "../utils/elasticUtils/appendClassName";
import getFilterValueDisplay from "../utils/elasticUtils/getFilterValueDisplay";

interface RangeOption {
  from: number;
  to: number;
  count: number;
}

interface FacetProps {
  className?: string;
  label?: string;
  onRemove?: (value: any) => void;
  onSelect?: (value: any) => void;
  options?: RangeOption[];
  showMore?: boolean;
  isDate?: boolean;
  category?: string;
}

export default function RangeFacetComponent({
  className,
  label,
  onRemove,
  onSelect,
  options = [],
  showMore,
  isDate = false,
  category,
}: FacetProps) {
  const [selectedOption, setSelectedOption] = useState<RangeOption | undefined>(
    undefined,
  );
  const [optionsState, setOptionsState] = useState<RangeOption[]>(options);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  useEffect(() => {
    setSelectedOption(undefined);
  }, [category]);

  const handleSelect = (option: RangeOption) => {
    const newSelection =
      selectedOption &&
      selectedOption.from === option.from &&
      selectedOption.to === option.to
        ? undefined
        : option;

    setSelectedOption(newSelection);

    if (newSelection) {
      onSelect && onSelect({ from: newSelection.from, to: newSelection.to });
    } else if (selectedOption) {
      onRemove &&
        onRemove({ from: selectedOption.from, to: selectedOption.to });
    }
  };

  const handleShowMore = () => {
    setIsShowMore(true);
  };

  const displayedOptions = isShowMore ? optionsState : optionsState.slice(0, 5);

  return (
    <fieldset className={appendClassName("sui-facet", className)}>
      <legend className="sui-facet__title">{label}</legend>

      <div className="sui-multi-checkbox-facet">
        {displayedOptions.length < 1 && <div>No matching options</div>}
        {displayedOptions.map((option) => {
          const isSelected =
            selectedOption &&
            selectedOption.from === option.from &&
            selectedOption.to === option.to;
          return (
            <label
              key={`${option.from}-${option.to}`}
              htmlFor={`example_facet_${label}${option.from}-${option.to}`}
              className="sui-multi-checkbox-facet__option-label">
              <div className="sui-multi-checkbox-facet__option-input-wrapper">
                <input
                  data-transaction-name={`facet - ${label}`}
                  id={`example_facet_${label}${option.from}-${option.to}`}
                  type="checkbox"
                  className="sui-multi-checkbox-facet__checkbox"
                  checked={isSelected}
                  onChange={() => handleSelect(option)}
                />
                <span className="sui-multi-checkbox-facet__input-text">
                  {getFilterValueDisplay(option)}
                </span>
              </div>
              <span className="sui-multi-checkbox-facet__option-count">
                {option.count}
              </span>
            </label>
          );
        })}
      </div>

      {showMore && !isShowMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={handleShowMore}
          aria-label="Show more options">
          + More
        </button>
      )}
    </fieldset>
  );
}
