export const supplierFacets = {
  demographic__country_name: { type: "value" },
  demographic__location_code: { type: "value" },
  demographic__city_name: { type: "value" },
  "sales__export_country_agg.export_country": { type: "value" },

  demographic__last_shipment_date: { type: "value" },

  "compliance__social_compliance.A": {
    type: "range",
    ranges: [
      { from: 1, name: "Any" },
      { from: 1, to: 3, name: "Up to 5" },
      { from: 1, to: 7, name: "Up to 10" },
      { from: 1, to: 20, name: "Up to 20" },
    ],
  },
  "compliance__social_compliance.B": {
    type: "range",
    ranges: [
      { from: 1, name: "Any" },
      { from: 1, to: 3, name: "Up to 5" },
      { from: 1, to: 7, name: "Up to 10" },
      { from: 1, to: 20, name: "Up to 20" },
    ],
  },
  "compliance__social_compliance.C": {
    type: "range",
    ranges: [
      { from: 1, name: "Any" },
      { from: 1, to: 3, name: "Up to 5" },
      { from: 1, to: 7, name: "Up to 10" },
      { from: 1, to: 20, name: "Up to 20" },
    ],
  },
  "compliance__social_compliance.D": {
    type: "range",
    ranges: [
      { from: 1, name: "Any" },
      { from: 1, to: 3, name: "Up to 5" },
      { from: 1, to: 7, name: "Up to 10" },
      { from: 1, to: 20, name: "Up to 20" },
    ],
  },
  "compliance__social_compliance.UNRATED": {
    type: "range",
    ranges: [
      { from: 1, name: "Any" },
      { from: 1, to: 3, name: "Up to 5" },
      { from: 1, to: 7, name: "Up to 10" },
      { from: 1, to: 20, name: "Up to 20" },
    ],
  },
  "sales__product_category_cogs.product_category": {
    type: "value",
  },
};

export const factoryFacets = {
  demographic__last_shipment_date: { type: "value" },
  demographic__FACTORY_TYPE: { type: "value" },

  "sales__product_category_cogs.product_category": {
    type: "value",
  },
  compliance__social_compliance_rating: { type: "value" },
};

export const productFacets = {
  product_family: { type: "value" },
  product_category: { type: "value" },
  product_category_group: { type: "value" },
};

export const customerFacets = {
  demographic__country_name: { type: "value" },

  demographic__last_shipment_date: { type: "value" },
};
