import RCPagination from "rc-pagination";

interface SearchPagingProductProps {
  current: number;
  resultsPerPage: number;
  onChange: (value: number) => void;
  totalPages: number;
}

export const SearchPagingProduct = ({
  current,
  resultsPerPage,
  onChange,
  totalPages,
}: SearchPagingProductProps) => (
  <RCPagination
    current={current}
    onChange={onChange}
    pageSize={resultsPerPage}
    total={totalPages * resultsPerPage}
    showPrevNextJumpers={false}
    prevIcon={() => <p style={{ marginTop: 0 }}>‹</p>}
    nextIcon={() => <p style={{ marginTop: 0 }}>›</p>}
    className="sui-paging"
  />
);
