import { Layout, Row, Col, Typography, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { NavBar } from "../components/NavBar";
import { useCallback, useContext, useEffect, useState } from "react";
import { ErrorBoundary, withSearch } from "@elastic/react-search-ui";
import { ResultCard } from "../components/ResultCard";
import { ResultProductCard } from "../components/ResultProductCard";
import { CategoryGenerator } from "../components/CategoryGenerator";
import {
  SourceType,
  formatProductResult,
  formatFacilityResult,
  PostTransactionType,
} from "../models/dataType";
import { SearchErrorPage } from "./SearchErrorPage";
import { SearchMaintenancePage } from "./SearchMaintenancePage";

import {
  Filter,
  FilterType,
  FilterValue,
  SearchResult,
} from "@elastic/search-ui";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useShortLists } from "../contexts/ShortListContext";
import { isShortListed } from "../services/ShortListService";
import { useCompareLists } from "../contexts/CompareListContext";
import { UserContext } from "../contexts/UserInfoContext";
import { postTransaction } from "../services/TransactionService";
import { useMsalWrapper } from "../contexts/MSALContext";
import {
  getCustomerClient,
  getFactoryClient,
  getProductClient,
  getSupplierClient,
} from "../services/SearchService";
import { ImageSearchPagingInfo } from "../components/ImageSearchPagingInfo";
import {
  customerFacets,
  factoryFacets,
  productFacets,
  supplierFacets,
} from "../models/facets";
import { ImageSearchPaging } from "../components/ImageSearchPaging";
import { getImageByText } from "../services/ImageSearchService";
import { SearchFiltersCustom } from "../components/SearchFiltersCustom";
import { SearchPagingProduct } from "../components/SearchPagingProduct";
const { Header, Content } = Layout;
const { Title } = Typography;

interface SearchProps {
  resultSearchTerm: string;
  setSearchTerm: (term: string) => void;
  results: SearchResult[];
  filters: Filter[] | undefined;
  setFilter: (
    name: string,
    value: FilterValue,
    type?: FilterType | undefined,
  ) => void;
  clearFilters: (except?: string[] | undefined) => void;
  totalResults: number;
  isLoading: boolean;
}

type FacetItem = {
  [key: string]: any;
};

type FacetsByCategory = {
  [category: string]: FacetItem[];
};

const categories = [
  "Suppliers",
  "Factories",
  "Products",
  "Customers",
  "Compare",
];

// TODO: Adjust category map for new filters
const categoryMap: { [index: string]: SourceType } = {
  Suppliers: "supplier",
  Factories: "factory",
  // Products: "product",
  Customers: "customer",
  // Compare: "compare"
};

const clientMap: { [index: string]: any } = {
  Suppliers: getSupplierClient(),
  Factories: getFactoryClient(),
  Products: getProductClient(),
  Customers: getCustomerClient(),
};

const facetMap: { [index: string]: any } = {
  Suppliers: supplierFacets,
  Factories: factoryFacets,
  Products: productFacets,
  Customers: customerFacets,
};

const SearchPrecontent = () => {
  const { id } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeCategory, setActiveCategory] = useState(id ? id : "Suppliers");

  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [totalMetaPages, setMetaTotalPages] = useState<number>(0);
  const [totalMetaResults, setMetaTotalResults] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [apiToken, setApiToken] = useState<string>("");

  const [currentFacets, setCurrentFacets] = useState<FacetsByCategory>({});
  const [currentFilters, setCurrentFilters] = useState<Filter[]>([]);
  const [currentPageProducts, setCurrentPageProducts] = useState<number>(1);

  const searchTerm = searchParams.get("q") ?? "";

  const { t } = useTranslation();

  const pageSize = activeCategory === "Products" ? 100 : 20;

  const navigate = useNavigate();

  const { shortListIds } = useShortLists();

  const user: any = useContext(UserContext);
  const isLFEU = user?.divisions?.includes("lfeu") ?? false;
  // const isLFEU = false;

  const { acquireToken } = useMsalWrapper();

  const { isItemCompare, getTotalLength, supplierListItems, factoryListItems } =
    useCompareLists();

  const [compareLength, setCompareLength] = useState<number>(getTotalLength);

  const handleSetActiveCategory = (category: any) => {
    setCurrentFilters([]);
    setResults([]);
    setMetaTotalPages(0);
    setMetaTotalResults(0);
    setLoading(true);
    setActiveCategory(category);
    if (category === "Compare") {
      navigate("/compare");
      return;
    }
  };

  useEffect(() => {
    const urlCategory = searchParams.get("c");

    if (urlCategory) {
      setActiveCategory(urlCategory);
    }
  }, [searchParams]);

  useEffect(() => {
    setCurrentPage(1);
    setCurrentPageProducts(1);
  }, [searchParams, currentFilters]);

  useEffect(() => {
    setCurrentFilters([]);
  }, [activeCategory, searchParams]);

  useEffect(() => {
    setCompareLength(getTotalLength);
  }, [supplierListItems, factoryListItems, getTotalLength, setCompareLength]);

  useEffect(() => {
    const sendTransaction = async () => {
      const token = await acquireToken();

      if (token) {
        setApiToken(token);
      }

      if (!token || !user || isLFEU === undefined) return;

      const transaction: PostTransactionType = {
        user: user.token,
        method: "GET",
        path: "Search",
        response: "200",
        accessedDate: parseInt(Date.now().toString()),
        payload: JSON.stringify({
          searchTerm: searchTerm,
          // filters: filters,
          totalResults: totalMetaResults,
        }),
      };

      await postTransaction(token, transaction);
    };

    if (isLFEU !== undefined) {
      sendTransaction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [user, isLFEU]);

  const getProductByText = useCallback(
    async (searchTerm: string) => {
      const response = await getImageByText(searchTerm, apiToken);
      return response;
    },
    [apiToken],
  );

  const getMultiProduct = useCallback(
    async (images: { id: number; distance: number }[]) => {
      const client = getProductClient();
      const options = {
        search_fields: { image_url: {} },
        filters: isLFEU
          ? activeCategory === "Products"
            ? { all: [...currentFilters, { operating_group: "LFEU" }] }
            : { all: [...currentFilters, { lf__LFEU: "true" }] }
          : { all: [...currentFilters] },
      };

      const searches = images.map((image) => ({
        query: image.id.toString(),
        options,
      }));

      const responses = await client.multiSearch(searches, options);

      const positives = responses.filter((r: any) => r.rawResults.length > 0);

      const items = positives
        .map((item: any) => {
          const data = (item.rawResults as any[]).find((r: any) => {
            const url = r.image_url.raw;
            return images.some(
              (image) => url.indexOf(image.id.toString()) > -1,
            );
          });

          if (data) {
            const index = images.findIndex(
              (image) => data.image_url.raw.indexOf(image.id.toString()) > -1,
            );
            data._meta.score = images[index].distance;
            return data;
          } else {
            return undefined;
          }
        })
        .filter((i: any) => i !== undefined);

      return items;
    },
    [activeCategory, currentFilters, isLFEU],
  );

  const getProductsByIds = useCallback(
    async (textProductIds: any[]) => {
      const size = Math.ceil(textProductIds?.length / 10);

      const results: any[] = [];
      const seenId: any[] = [];

      for (let i = 0; i < size; i++) {
        const batch = textProductIds.slice(i * 10, (i + 1) * 10);

        const response = await getMultiProduct(
          batch.map((image) => ({
            id: image.imageID,
            distance: image.distance,
          })),
        );

        response.forEach((product: any) => {
          if (!seenId.includes(product.item_run_number.raw)) {
            seenId.push(product.item_run_number.raw);
            results.push(product);
          }
        });
      }

      return results;
    },
    [getMultiProduct],
  );

  const getClient = useCallback(
    async (searchTerm: string, currentFilters: Filter[]) => {
      const currentFacet = facetMap[activeCategory];

      const filters = isLFEU
        ? activeCategory === "Products"
          ? { all: [...currentFilters, { operating_group: "LFEU" }] }
          : { all: [...currentFilters, { lf__LFEU: "true" }] }
        : { all: [...currentFilters] };

      const options = {
        page: { size: pageSize, current: currentPage },
        filters: filters,
        facets: currentFacet,
      };
      const currentClient = clientMap[activeCategory];

      const result = await currentClient.search(searchTerm, options);

      setCurrentFacets((prevFacets) => ({
        ...prevFacets,
        [activeCategory]: result?.rawInfo?.facets || [],
      }));

      return result;
    },
    [activeCategory, currentPage, isLFEU, pageSize],
  );

  const handleGetResults = useCallback(async () => {
    if (!searchTerm) {
      return;
    }

    console.log("handleGetResults entered");

    try {
      setResults([]);
      setLoading(true);
      const elasticResults = await getClient(
        searchTerm,
        currentFilters.length > 0 ? currentFilters : [],
      );

      let mergedResults = elasticResults.rawResults;

      if (activeCategory === "Products") {
        const textProductIds = await getProductByText(searchTerm);
        const textProducts = await getProductsByIds(textProductIds);

        if (mergedResults.length > 0) {
          const maxScore = (mergedResults as any[]).reduce((acc, curr) =>
            acc._meta.score > curr._meta.score ? acc : curr,
          )._meta.score;

          const overlap = textProducts
            .filter((p) => {
              return (mergedResults as any[]).some(
                (i: any) => i.item_run_number.raw === p.item_run_number.raw,
              );
            })
            .map((p) => ({
              item: p.item_run_number.raw,
              value: textProducts.find(
                (i) => i.item_run_number.raw === p.item_run_number.raw,
              )._meta.score,
            }));

          const maxImageScore: number =
            overlap.length > 0
              ? overlap.reduce((acc, curr) =>
                  acc.value > curr.value ? acc : curr,
                ).value
              : 1;

          mergedResults = mergedResults.map((p: any) => {
            const isOverlap = overlap.findIndex(
              (n) => n.item === p.item_run_number.raw,
            );

            const normalized = (p._meta.score / maxScore).toString();

            const boost: number =
              isOverlap > -1
                ? ((overlap[isOverlap].value / maxImageScore) as number)
                : 0;

            const normalizedBoost = (boost / maxImageScore).toString();

            p._meta.score =
              parseFloat(normalized) + parseFloat(normalizedBoost);

            return p;
          });
        } else {
          mergedResults = textProducts;
        }

        mergedResults = mergedResults?.sort((a: any, b: any) =>
          a._meta.score > b._meta.score ? -1 : 1,
        );
      }

      if (activeCategory === "Products") {
        setMetaTotalPages(Math.ceil(mergedResults.length / 20));
        setMetaTotalResults(mergedResults.length);
        setResults(mergedResults);
      } else {
        setMetaTotalPages(elasticResults.info.meta.page.total_pages);
        setMetaTotalResults(elasticResults.info.meta.page.total_results);
        setResults(elasticResults.rawResults);
      }

      // setResults(mergedResults);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    activeCategory,
    currentFilters,
    getClient,
    getProductByText,
    getProductsByIds,
    searchTerm,
  ]);

  useEffect(() => {
    const interval = setTimeout(() => handleGetResults(), 1000);
    return () => clearTimeout(interval);
  }, [handleGetResults]);

  useEffect(() => {
    setCurrentPageProducts(1);
  }, [searchParams, activeCategory]);

  const sliceResults = useCallback(
    (results: any[]) => {
      const indexStart = (currentPageProducts - 1) * 20;
      const indexEnd = indexStart + 20;

      return results.slice(indexStart, indexEnd);
    },
    [currentPageProducts],
  );

  return (
    <Content className="content">
      <ErrorBoundary>
        <Row id="search-title">
          <div id="box" />
          <Title level={2}>
            {t("Search Results for") + ` "${searchTerm}"`}
          </Title>
        </Row>
        <Row className="categories">
          {CategoryGenerator(
            categories,
            activeCategory,
            handleSetActiveCategory,
            totalMetaResults,
            compareLength,
          )}
        </Row>
        {!loading && (
          <Row id="search-paging">
            <ImageSearchPagingInfo
              page={
                activeCategory === "Products"
                  ? currentPageProducts
                  : currentPage
              }
              currentPage={
                activeCategory === "Products"
                  ? sliceResults(results).length
                  : results.length
              }
              totalResults={totalMetaResults}
            />

            <Row>
              {activeCategory === "Products" ? (
                <SearchPagingProduct
                  current={currentPageProducts}
                  onChange={(selectedPage: number) =>
                    setCurrentPageProducts(selectedPage)
                  }
                  resultsPerPage={20}
                  totalPages={totalMetaPages}
                />
              ) : (
                <ImageSearchPaging
                  current={currentPage}
                  onChange={(selectedPage: number) =>
                    setCurrentPage(selectedPage)
                  }
                  resultsPerPage={pageSize}
                  totalPages={totalMetaPages}
                />
              )}
            </Row>
          </Row>
        )}

        <Row className="search-body" wrap={false}>
          <Col>
            {!loading && (
              <SearchFiltersCustom
                facets={currentFacets[activeCategory] || []}
                onSearch={async (value) => {
                  setCurrentFilters(value);
                }}
                category={activeCategory}
              />
            )}
          </Col>
          <Col className="results" flex={"auto"}>
            {loading ? (
              <Spin tip="Loading..."></Spin>
            ) : totalMetaResults > 0 ? (
              activeCategory !== "Compare" &&
              (activeCategory !== "Products" ? (
                <>
                  {results?.map((result: any) => {
                    const data = formatFacilityResult(
                      categoryMap[activeCategory],
                      result,
                    );
                    const isShortList = isShortListed(data.code, shortListIds);
                    const compare = isItemCompare(
                      categoryMap[activeCategory],
                      data.code,
                    );
                    return (
                      <ResultCard
                        source={categoryMap[activeCategory]}
                        result={result}
                        shortListed={isShortList}
                        isCompared={compare}
                        key={result.id.raw}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {sliceResults(results)?.map((result: any) => {
                    const itemNumber = result.item_number?.raw
                      ? result.item_number.raw
                      : result.item_number;
                    const isShortList = isShortListed(itemNumber, shortListIds);

                    const isLFEUProduct =
                      result.operating_group?.raw === "LFEU" ? true : false;

                    const showImage = isLFEUProduct
                      ? isLFEU
                        ? true
                        : false
                      : true;

                    return (
                      <ResultProductCard
                        data={formatProductResult(result)}
                        shortListed={isShortList}
                        key={result.id.raw}
                        showImage={showImage}
                      />
                    );
                  })}
                </>
              ))
            ) : (
              <SearchErrorPage resultSearchTerm={searchTerm} />
            )}
          </Col>
        </Row>

        {/* <Row id="search-paging">
          <div />
          <Paging />
        </Row> */}
      </ErrorBoundary>
    </Content>
  );
};

const SearchContent = withSearch((SearchProps) => SearchProps)(
  SearchPrecontent,
);

export const SearchPage = () => {
  if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
    return <SearchMaintenancePage />;
  } else {
    return (
      <Layout className="frame">
        <Header className="header">
          <NavBar />
        </Header>
        <SearchContent />
      </Layout>
    );
  }
};
